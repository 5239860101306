import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { makeStyles } from "@material-ui/core/styles";
import "./divers.css"
import Grid from '@material-ui/core/Grid';

import { livres_as_bg,livres_dr_cheick } from '../../constants/divers';
import {motion} from "framer-motion"
import { useEffect } from "react";
import Carousel_slide from "./components/Carousel/Carousel";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/test/Footer";
import CardBook from "./components/CardBook/CardBook";
import { FlyoutNav } from "../../components/New/HomeHeader";


const useStyles = makeStyles({
body:{
  background:"orange",
 
},
  
});

function Divers() {
  const classes = useStyles();

  useEffect(()=>{
    
  })

  return (
    <motion.div
      initial={{ opacity : 0 }}
      animate={{ opacity : 1 }}
      exit={{ opacity : 0 }}
    >
      <FlyoutNav />
      
      <motion.div 
          className="divers"
          initial={{ opacity : 0 }}
          animate={{ opacity : 1 }}
          exit={{ opacity : 0 }}
      >
        <div className={classes.body}>
          <Grid container 
          >
          <Grid item xs={12} ></Grid>
          <Grid item  xs={12}>
            <div className="w-full">
            <Carousel_slide data={livres_as_bg} hauteur={"80vh"} w_hauteur={"70vh"} />
            </div>
            
          </Grid>
          </Grid>
          
          
          <div className="livres">
          {livres_dr_cheick.map((item,index)=>{
              return(
                <CardBook image={item.image} />
              )
            })}
          </div>
        </div>
        
        
      </motion.div>

      <Footer />
    </motion.div>
  );
}

export default Divers;
