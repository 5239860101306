import React from 'react'
import "./cardbook.css"

const CardBook = ({image}) => {
  return (
    <div className='cardbook'> 
        <img src={image} alt='livre' />
    </div>
  )
}

export default CardBook