import { AnimatePresence, motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import ReactPlayer from 'react-player/youtube'



const HomeModal = ({ isOpen, setIsOpen }) => {
  const video_apercu = "https://youtu.be/OYDwA6lO9mA?si=mHspn_GN3aFjz3W-"

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className="bg-slate-900/20 backdrop-blur p-4 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
          
        >
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className=" text-white p-3 rounded-lg shadow-xl cursor-default relative overflow-hidden"
            style={{width:"95%",height:"80%",marginTop:"5vh",position:"relative", marginLeft:"-4vh"}}
          >
            

            <ReactPlayer url={video_apercu} width={"100%"} height={"100%"} playing/> 
                <button
                  onClick={() => setIsOpen(false)}
                  className="bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded"
                >
                  <FontAwesomeIcon icon={faCircleXmark} style={{position:"absolute",top:"1vh",right:"1vh",width:"40px",height:"40px"}}/>
                </button>
               
      
          
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default HomeModal;

