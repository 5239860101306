import React,{useEffect} from 'react'
import "./about.css"
import "./equipe.scss"
import { motion } from 'framer-motion'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

import about_blur from "../../assets/images/about/about_blur.png"
import Reveal from '../../components/Reveal/Reveal';
import TeamMembers from './components/TeamMembers';

import { staff } from '../../constants/staff';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import africa from '../../assets/images/about/africa.png';
import micro from '../../assets/images/about/micro.png';
import presentateur from '../../assets/images/about/presentateur.png';
import CardInfos from './components/CardInfos/CardInfos';
import Counter_stats from '../Home/components/Counter/Counter';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/test/Footer';

import Propos from '../../assets/images/about/A_propos.jpg'
import { FlyoutNav } from '../../components/New/HomeHeader';
import Personal from '../../components/New/Personal';

const About = () => {

/*
  useEffect(()=>{
    var tabs = new Tabby('[data-tabs]');
  })*/

  const infos = [
    {
      title:"Spécialistes de la communication et du discours",
      description:"Composée d'une douzaine de professionnels, notre institution est spécialisée dans la prise de parole en public et le leadership;"+
      "Première du genre en Afrique Francophone. Nous mettons notre expertise au service des professionnels du public et du privé, des politiques, des dirigeants d'entreprise,"+
      "des leaders et des managers. A ce jour, nous avons formé environ plus de sept mille orateurs",
      icon:faBookOpen,
      image:micro
    },
    {
      title:"Experts formateurs aux talents multiples",
      description:"Professeurs, experts, consultants en communication, journalistes et formateurs, nos parcours professionnels sont divers et variés.",
      icon:faBookOpen,
      image:presentateur
    },
    {
      title:"Forts d'une pratique africaine",
      description:"Nous intervenons en Afrique à partir du Sénégal, d'une part, auprès des institutions publiques, régionales et africaines et de l'autre, auprès des PME-PMI,"+
      "des ONG, des professionnels du secteur public et du secteur privé.",
      icon:faBookOpen,
      image:africa
    },
  ]

  return (
    <motion.div
      initial={{ opacity : 0 }}
      animate={{ opacity : 1 }}
      exit={{ opacity : 0 }}
    >

    <FlyoutNav />
        
    <motion.div 
      className="about_carousel"
      
      initial={{ opacity : 0 }}
      animate={{ opacity : 1 }}
      exit={{ opacity : 0 }}
    >
      
  
      <div className='header_about_carousel' >
        <div className="header_about_wrap_image">
          <LazyLoadImage 
            effect="blur"
            src={Propos}
            alt="Image Alt"
            key="1"
            className="header_about_image" 
            height={"100%"}
            width={"100%"}
            placeholderSrc={about_blur}
            />
        </div>
        
        <section className='about_introduction'>
            <Reveal>
              <div class="about_content_intro_">
                  <h2 style={{color:"white"}}> {/*À PROPOS*/}</h2>
              </div>
            </Reveal>
        </section>

          <div class="custom-shape-divider-bottom-1694260240">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
            </svg>
        </div>

        </div>

        
        <div className='breve_intro'>
         
          <h4>Qui sommes-nous pour vous offrir des principes et méthodes, des techniques et secrets sur la manière de communiquer et de prendre la parole en public?🤷‍♂️</h4>
          
          <div className='breve_intro_infos'>
            {
              infos.map((item,index)=>{
                return(
                  <CardInfos icon={item.icon} title={item.title} description={item.description} image={item.image} />
                )
              })
            }
          </div>
          
        </div>

        <div className='nos_performances'>
            <div className="nos_performances_bloc">
                <div className="flex flex-row">
                  <span className="number" style={{color:"#f9f9f9"}}>+</span>
                  <Counter_stats max_number={7000} stat={"+"} color={"#f9f9f9"}/>
                </div>
                <h4 className="nos_performances_bloc_text">Orateurs Formés</h4>
            </div>
            <div className="nos_performances_bloc">
                <div className="flex flex-row">
                  <span className="number" style={{color:"#f9f9f9"}}>+</span>
                  <Counter_stats max_number={200} stat={"+"} color={"#f9f9f9"}/>
                </div>
                <h4 className="nos_performances_bloc_text" style={{color:"#1F1D0D"}}>Promotions</h4>
            </div>
            <div className="nos_performances_bloc">
                <div className="flex flex-row">
                  <Counter_stats max_number={5} stat={""} color={"#f9f9f9"}/>
                </div>
                <h4 className="nos_performances_bloc_text">Formations de Référence</h4>
            </div>
            <div className="nos_performances_bloc">
                <div className="flex flex-row">
                  <span className="number" style={{color:"#f9f9f9"}}>+</span>
                  <Counter_stats max_number={12} stat={"+"} color={"#f9f9f9"}/>
                </div>
                <h4 className="nos_performances_bloc_text">Experts Formateurs</h4>
            </div>
          
        </div>

        <div className='body_about'>
          <Personal />
          {/* 
          
           <ul className='w-full flex flex-row justify-around'>
              <li ><a data-tabby-default href="#pedagogique" className='' style={{color: "#FFA500"}}>Staff Pédagogique</a></li>
            <li><a href="#administratif" className='' style={{color: "#FFA500"}}>Staff Administratif</a></li>
            <li><a href="#technique" className='' style={{color: "#FFA500"}}>Staff Technique</a></li>
          </ul>


          <div id="pedagogique">
            <div className='w-full flex flex-row flex-wrap justify-center'>
              {staff.filter((item,index)=> item.staff_statut === "Pédagogique").map((item,index)=>{
                return (
                  <TeamMembers image={item.image} name={item.name} fonction={item.title} description={item.profession} key={index}/>
                )
              })}
            </div>
          </div>

          <div id="administratif">
            <div className='w-full flex flex-row flex-wrap justify-center'>
              {staff.filter((item,index)=> item.staff_statut === "Administratif").map((item,index)=>{
                return (
                  <TeamMembers image={item.image} name={item.name} fonction={item.title} description={item.profession} key={index}/>
                )
              })}
            </div>
          </div>

          <div id="technique">
            <div className='w-full flex flex-row flex-wrap justify-center'>
                {staff.filter((item,index)=> item.staff_statut === "Technique").map((item,index)=>{
                  return (
                    <TeamMembers image={item.image} name={item.name} fonction={item.title} description={item.profession} key={index}/>
                )
              })}
            </div>
          </div>
          
          */}
         

        </div>
        
    </motion.div>

    <Footer />
  </motion.div>
  )
}

export default About