
import mazar from "../assets/images/actualites/mazar.jpeg"

import cbao from "../assets/images/actualites/cbao.jpeg"


import eloquence from "../assets/images/actualites/eloquence.jpeg"

import institut from "../assets/background/institut.jpeg"

import formation from "../assets/images/actualites/formation_entreprise.jpeg"

export const actualites_ = [
  {
    id: 11,
    presentateur: eloquence,
    date: "Ouverture le 02 Septembre 2024",
    title:"Gagner en Eloquence",
    description:"Prise de Parole avancée - Communication efficace",
    resume:["Gagner en Eloquence","Prise de Parole avancée - Communication efficace"],
    images: [
      {
        src:eloquence,
        title: "A Title",
        id: 1,
      },
      
    ]

  },
  {
    id: 10,
    presentateur: formation,
    date: "Ce Mercredi 24 au Vendredi 26 Juillet 2024",
    title:"Prise de Parole en Milieu Professionnel",
    description:"Prise de Parole en Milieu Professionnel avec les cadres de la CBAO Groupe Attijariwafa bank",
    resume:["Prise de Parole en Milieu Professionnel avec les cadres de la CBAO Groupe Attijariwafa bank","Formation dans les locaux de l'Ecole d'Art Oratoire et de Leadership"],
    images: [
      {
        src:formation,
        title: "A Title",
        id: 1,
      },
      
    ]

  },
    {
    id: 9,
    presentateur: cbao,
    date: "Le 16, 18 et 19 juillet",
    title:"Formation Entreprise",
    description:"Cadres de la CBAO : Prise de Parole en Milieu Professionnel",
    resume:["Formation Entreprise - Cadres de la CBAO","Prise de Parole en Milieu Professionnel"],
    images: [
      {
        src:cbao,
        title: "A Title",
        id: 1,
      },
      
    ]

  },
  {
    id: 8,
    presentateur: institut,
    date: "Du 02 au 05 juillet",
    title:"Formation Institutionnelle",
    description:"Banque Ouest Africaine de développement",
    resume:["Formation Institutionnelle - Banque Ouest Africaine de développement","Directeur de département", 
      "Thème: Commmunication d'impact et Leadership de Service","","","Lieu: Hôtel SARAKAWA à Lomé","","",'Formateur: Dr Cheikh Omar DIALLO'],
    images: [
      {
        src:institut,
        title: "A Title",
        id: 1,
      },
      
    ]

  },
  {
    id: 7,
    presentateur: mazar,
    date: "Du 19 juin au 21 juin",
    title:"FORVIS MAZARS",
    description:"Art Oratoire et Media Training",
    resume:["Formation Entreprise - FORVIS MAZARS","Art Oratoire et Media Training","FORVIS MAZARS - AOC Seniors"],
    images: [
      {
        src:mazar,
        title: "A Title",
        id: 1,
      },
      
    ]

  },
    
    


]