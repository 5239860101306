import React,{useEffect} from 'react'
import ActualitesCarousel from './components/Carousel'

import actualite from "../../assets/background/journal.jpg"
import { FlyoutNav } from '../../components/New/HomeHeader'
import { useLocation } from 'react-router-dom'
import { Post } from '../../components/New/Actualites'

import { actualites_ } from '../../constants/actualites'
import Footer from '../../components/test/Footer'

import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

import "./components/style.css"
import about_blur from "../../assets/images/about/about_blur.png"

const DetailActualite = (props) => {
    const location = useLocation()
    const {
        presentateur,
        date,
        title,
        description,
        resume,
        index,
        images
    } = location.state.data
    useEffect(() => {
        console.log("Mes données: ",resume)
      }, []);
    
  return (
    <div className='flex flex-col gap-50 ' style={{backgroundColor: "f9f9f9"}}>
        <FlyoutNav />
        <div className='w-full h-[55vh] mb-[5vh] rounded-b-md'>
            <LazyLoadImage 
            effect="blur"
            src={actualite}
            alt="Image Alt"
            key="1"
            className="w-full h-full object-cover rounded-b-xl" 
            height={"100%"}
            width={"100%"}
            placeholderSrc={about_blur}
            />
        </div>
        <p className='text-center font-bold text-3xl my-[5vh]'>{title}</p>
        <div className='w-full h-[65vh] flex flex-col justify-center items-center px-[10%] mb-[15vh] md:flex-row'>
            <div className='w-full h-full md:w-[60%]'>
                <ActualitesCarousel images={images}/>
            </div>
            <div className='w-full h-full md:h-full md:w-[40%] grainy rounded-r-md p-[2%] md:overflow-y-scroll'>
                <p className='text-black font-bold text-lg'>{title}</p>
                <div className=' w-[6rem] rounded-md border-[1px] border-neutral-500 px-1.5 py-1 text-xs uppercase text-neutral-500'>
                    {date}
                </div>
                <div className='mt-[2vh]'>
                    <p className='text-xs text-black'>Description:</p>
                    { resume &&
                        resume.map((desc,index) => {
                            return(
                                <p className='text-justify text-xs' key={index}>
                                    {desc}
                                </p>
                            )
                        })
                    }
                    
                </div>
            </div>
        </div>
        
        <div className=' mb-4 w-full  text-black text-2xl font-bold text-center md:flex md:justify-start md:pl-[10%]'>Recommendations</div>
        <div className='w-full flex flex-col justify-center items-center  px-[10%] mb-[10vh] md:flex-row md:justify-start'>        
            {actualites_.filter((post)=> post.id!=index).slice(0, 3).map((post) => {
              return <Post key={post.id} {...post} index={post.id} lien={`../actualite/${post.id}`}/>;
            })}
        </div>
        
        <Footer />
    </div>
  )
}

export default DetailActualite