import React from 'react'
import "./style.css"
import { Link } from 'react-router-dom'

const Card_ = ({title,image}) => {
  return (
      <Link 
        class="card_ 1"
        to="ecole/"
      >
        <div class="card_image"> <img src={image} /> </div>
        <div class="card_title title-white">
          <p className='paragraphe' style={{color:'#EB7120'}}>{title}</p>

        </div>
      </Link>
  )
}

export default Card_