import React from 'react';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';



export default function Carousel_slide({data,hauteur,w_hauteur}) {
  return (
    <div className="" style={{width:"100%",height:hauteur}}>
    <MDBCarousel showIndicators showControls fade style={{width:"100%",height:w_hauteur}}>
      <MDBCarouselItem
        className='d-block'
        itemId={1}
        src={data[0].image}
        alt='...'
        style={{width:"100%",height:hauteur}}
      >
        <h5>{data[0].title}</h5>
      
      </MDBCarouselItem>

      <MDBCarouselItem
        className='w-full h-96 d-block'
        itemId={2}
        src={data[1].image}
        alt='...'
        style={{width:"100%",height:hauteur}}
      >
        <h5>{data[1].title}</h5>
       
      </MDBCarouselItem>

      <MDBCarouselItem
        className='w-100 d-block'
        itemId={3}
        src={data[2].image}
        alt='...'
        style={{width:"100%",height:hauteur}}
      >
        <h5>{data[2].title}</h5>
        
      </MDBCarouselItem>
    </MDBCarousel>
    </div>
  );
}