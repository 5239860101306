export const ACCUEIL = "/"

export const ATELIER = "/atelier"

export const DIVERS ="/divers"

export const ECOLE ="/ecole"

export const ABOUT ="/about"

export const CONTACT ="/contact"

export const VIEW_PDF = "/view_pdf"

export const CALENDRIERS = "/calendriers"

//export const SENDMESSAGE = "http://127.0.0.1:5000/"

export const SENDMESSAGE = "https://eao-backend.vercel.app/"