import React,{useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom'

import Layout from './hocs/Layout';


import ScrollToTop from './components/ScroolToTop/ScrollToTop';
import AnimatedRoutes from './components/AnimatedRoutes/AnimatedRoutes';
import { useState } from 'react';
import {RingLoader} from "react-spinners"
import "./app.css"

import { Routes,Route, useLocation } from 'react-router-dom'
import Home from './pages/Home/Home'
import Ecole from './pages/Ecole/Ecole'
import Atelier from './pages/Ateliers/Atelier'
import Divers from './pages/Divers/Divers'
import Contact_US from './pages/Contact_US/Contact_US'
import About from './pages/About/About'
import ViewPdf from './pages/ViewPdf/ViewPdf';
import DetailActualite from './pages/DetailActualite/DetailActualite';
import DetailFormation from './pages/DetailFormation/DetailFormation';

function App() {
  const [loading,setLoading] = useState(false)

  useEffect(()=>{
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },2000)
  },[])

  /*if (loading) {
    return <div className='milieu'><h1>EAO</h1><RingLoader color='orange' size={150}/></div>;
  }*/

  return (
    <Router>
      {
        <>
          <ScrollToTop />
          <Routes>
              <Route path='*' element={
                loading?(<div className='milieu'><h1>EAO</h1><RingLoader color='orange' size={150}/></div>):(
                  <Home />
                )  
                }/>
              <Route exact path='/actualite/:id' element={<DetailActualite />} />
              <Route exact path='/formation/:id' element={<DetailFormation />} />
              <Route exact path='/ecole' element={<Ecole />} />
              <Route exact path='/atelier' element={<Atelier />} />
              <Route exact path='/divers' element={<Divers />} />
              <Route exact path='/about' element={<About />} />
              <Route exact path='/contact' element={<Contact_US />} />
              <Route exact path='/view_pdf' element={<ViewPdf />} />
          </Routes>
        </>
      }
      
        
    </Router>
      
    
  );
}

export default App;
