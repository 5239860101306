
export const videos_ateliers = [
    {

        video:"https://www.youtube.com/watch?v=QnyqGSZ9FWc&t=98s&ab_channel=ECOLED%27ARTORATOIREDEDAKAR",
        description:"Communication de Crise avec L'Hôpital Aristide le Dantec: Leçon Inaugurale par Dr Cheikh Omar DIALLO"
    }
]

export const ateliers =[
    {
        title:"Prise de Parole en Public",
        image:"https://media.licdn.com/dms/image/D4E05AQHtC6bIBjuT1A/videocover-high/0/1695667678785?e=2147483647&v=beta&t=8PZupNwWiONkvQih3uzQQ3RgSX6JyqsIdBqWP7XSyP4",
        definition:"Atelier dirigé par le Directeur et Fondateur, Dr Cheikh Omar DIALLO. Juriste, Docteur en Science politique et expert en communication, Dr Diallo capitalise 20 ans d'expérience.. Il est en duo avec Dr M. Lamine BIAYE, Docteur en Techniques d'expression écrite et orale"
    },
    {
        title:"Leadership & Management des Équipes",
        image:"https://www.barakagate.com/IMG/arton27.jpg",
        definition:"Atelier dirigé par le Directeur de l'EAO Dr Cheikh Omar DIALLO. Il est assisté par Pierre AVOUDJIGBE coach et expert en Prise de Parole et Leadership. Il capitalise une quinzaine d'années d'expérience"
    },
    {
        title:"Organisation & Gestion du Temps",
        image:"https://media.licdn.com/dms/image/C4D03AQGxRlOkupMpFQ/profile-displayphoto-shrink_200_200/0/1554292422519?e=1702512000&v=beta&t=qGSDhb1Hf2u_7fZ08amLDh1_ezLWMoVepO6XtixTeVM",
        definition:"Organisation & Gestion du Temps"
    },
    {
        title:"Gestion du Stress & du Trac",
        image:"https://www.financialafrik.com/wp-content/uploads/2017/07/10536300-17310631.jpg",
        definition:"Gestion du Stress & du Trac"
    },
    {
        title:"Media-Training & Charisme audiovisuel",
        image:"https://i0.wp.com/eao-afrique.com/wp-content/uploads/2023/03/WhatsApp-Image-2023-03-22-a-13.52.53.jpg?fit=800%2C531&ssl=1",
        definition:"Atelier dirigé par Mr Charles FAYE assisté par Madame Ndèye Fatou NIANG"
    },
    {
        title:"Techniques de Présentation - Art du Pitch",
        image:"https://media.licdn.com/dms/image/C4E22AQHwL7ok2B5zMg/feedshare-shrink_2048_1536/0/1619090705338?e=2147483647&v=beta&t=IsKxQtUMahrw5REW8dCHFsAtaHlCv_TfmrtvHrn7ep0",
        definition:"Atelier dirigé par Me Mamadou CISS, expert en communication et techniques de présentation. Il est assisté par Coach Pierre AVOUDJIGBE, Expert en Stratégies d'Éloquence et Art du Pitch"
    },
    {
        title:"Communication de Crise",
        image:"https://www.financialafrik.com/wp-content/uploads/2017/07/10536300-17310631.jpg",
        definition:"Atelier dirigé par le Directeur et Fondateur, Dr Cheikh Omar DIALLO. Juriste, Docteur en Science politique et expert en communication"
    },
    {
        title:"Conduite du Changement",
        image:"https://www.jeuneafrique.com/medias/2017/08/18/aaeaaqaaaaaaaaqyaaaajgq1m2rhoty0lwnkmzktngzins1hy2nllwy3mtrjnty4ytuzmw-1-592x296.jpg",
        definition:"Conduite du Changement"
    },
    {
        title:"Intelligence émotionnelle",
        image:"https://www.seneplus.com/sites/default/files/raw_photos/dr-cheikh-diallo.jpg",
        definition:"Intelligence émotionnelle"
    },
    {
        title:"Efficacité Professionnelle",
        image:"https://i0.wp.com/eao-afrique.com/wp-content/uploads/2022/04/DSC08155-scaled.jpg?fit=800%2C450&ssl=1",
        definition:"Efficacité Professionnelle"
    },
    {
        title:"Rédaction de Discours",
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjKY0JX1UKfFOdgOBuFbwHroVkYl-6AWrTOxmHDwxcNRtSZg1jBgNfYbUfe88HadFeQus&usqp=CAU",
        definition:"Rédaction de Discours"
    },
    {
        title:"Stratégies d'Éloquence",
        image:"https://media.licdn.com/dms/image/D4D22AQFpBzDzmiYWHg/feedshare-shrink_800/0/1695219198180?e=1698883200&v=beta&t=xlv8l2WXhGQV26UfteBzQtzk-WkPrczOxNyZ2RAMGa4",
        definition:"Stratégies d'Éloquence"
    },
    {
        title:"Techniques Argumentatives",
        image:"https://i0.wp.com/gnatepe.com/wp-content/uploads/2022/06/ARO-INTERNATIONAL-Lecole-par-excellence-de-lArt-oratoire-annonce-ses-plus-belles-couleurs-au-Togo.jpg?fit=1200%2C675&ssl=1",
        definition:"Atelier dirigé par Dr Cheikh Omar DIALLO. Il est en duo avec le Professeur Samba NDIAYE, maître de la ponctuation orale, présentateur du Concours général. Ils sont assistés par Coach Pierre AVOUDJIGBÉ, Fulgence GACKOU et Diamant ESSOU"
    },
    {
        title:"Techniques d'Improvisation",
        image:"https://www.financialafrik.com/wp-content/uploads/2017/07/10536300-17310631.jpg",
        definition:"Atelier dirigé par Me Mamadou CISS. Il est assisté par le duo Coach Pierre AVOUDJIGBÉ et Matar FAYE"
    },
    {
        title:"Techniques de Négociation et de Plaidoyer",
        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPyRx4lcSOqnuk_qrkkAZj8DULQRXvd6Mc6Vw8Vz0R7azBPnGaTQeuSZXjoVM4I-TDi3I&usqp=CAU",
        definition:"Techniques de Négociation et de Plaidoyer"
    },
    {
        title:"Techniques Rédactionnelles",
        image:"https://www.financialafrik.com/wp-content/uploads/2017/07/10536300-17310631.jpg",
        definition:"Techniques Rédactionnelles"
    },
    {
        title:"Techniques Vocales & Oratoires - Dynamisation Vocale",
        image:"https://eao-afrique.com/wp-content/uploads/2021/06/img-20210605-wa00174218697719823588197.jpg",
        definition:"Atelier dirigé par Me Pape FAYE, Professeur d’art oratoire, metteur en scène et maître de cérémonie de grands évènements, il a fait de sa voix un empire. Il compte une trentaine d’années sur scène. Il est en trio avec Diamant ESSOU et Fulgence GACKOU"
    },
    {
        title:"Culture d' Entreprise",
        image:"https://media.licdn.com/dms/image/D4E22AQGKDJm5ErIWIA/feedshare-shrink_800/0/1695300291687?e=1698883200&v=beta&t=CDOTo2518ovbhEzBi4hUhhi66Ke4vbuXoVGmhJko0kA",
        definition:"Culture d' Entreprise"
    },
    {
        title:"Force de Vente",
        image:"https://eao-afrique.com/wp-content/uploads/2021/05/img-20210327-wa00401826090837375304655.jpg",
        definition:"Force de Vente"
    },
    {
        title:"Pilotage de la Performance",
        image:"https://media.licdn.com/dms/image/D5622AQFPPQ1ZucwPcg/feedshare-shrink_800/0/1695170516994?e=1698883200&v=beta&t=RccOdOEumObysM7dc_VWDxPDPmO8MYgKhPnThtc7VRo",
        definition:"Pilotage de la Performance"
    },
    {
        title:"Team Building & Team-Bonding",
        image:"https://eao-afrique.com/wp-content/uploads/2021/06/img-20210605-wa00174218697719823588197.jpg",
        definition:"Team Building & Team-Bonding"
    },
    {
        title:"Techniques de Conciliation et de Médiation",
        image:"https://media.licdn.com/dms/image/C5112AQFxtltm1sFVsQ/article-cover_image-shrink_720_1280/0/1520138515343?e=2147483647&v=beta&t=cwRM-pALPxhriR6ghPPShl6Cq0-CMCfLUfwLxQX3wH8",
        definition:"Techniques de Conciliation et de Médiation"
    },
    {
        title:"Techniques d'Accueil & et Communication Commerciale",
        image:"https://media-files.abidjan.net/photo/leadership-et-art-oratoire-lancement-des-activites-de-tostmasters-internati_85dyfr88d0j.jpg",
        definition:"Techniques d'Accueil & et Communication Commerciale"
    },

]


