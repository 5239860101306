import React from 'react'
import "./cardinfos.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons'

const CardInfos = ({icon,title,description,image}) => {
  return (
    <div className='new_about_head'>
         
          <div className='new_about_comp'>
            <div className='new_about_comp_head'>
              <div className='new_about_comp_head_logo'>
                {/*<FontAwesomeIcon icon={icon} style={{color:"#FFA500"}}/>*/}
                <img src={image} alt="Logo" className='logo_section'/>
              </div>
              <div className='new_about_comp_head_text'>
              {title}
              </div>
            </div>
            <div className='new_about_comp_body'>
             {description}
             <br/><br/><br/>
            </div>
          </div>
        </div>
  )
}

export default CardInfos