
//*************************** PEDAGOGIQUE ***************************************************** */
import diamant_essou from "../assets/images/home/staff/pedagogique/Diamant_ESSOU.jpg"
import matar_faye from "../assets/images/home/staff/pedagogique/Maitre_Matar_FAYE.png"
import charles_faye from "../assets/images/home/staff/pedagogique/Me_Charles_FAYE.jpg"
import mamadou_ciss from "../assets/images/home/staff/pedagogique/Me_Mamadou_CISS.jpg"
import pierre_avoudjigbe from "../assets/images/home/staff/pedagogique/Me_Pierre_AVOUDJIGBE.png"
import ndeye_faye from "../assets/images/home/staff/pedagogique/Ndéné_FAYE.jpg"
import mouhamadou from "../assets/images/home/staff/pedagogique/Mouhamadou_Moustapha_LO.jpg"
import ndeye_fatou from "../assets/images/home/staff/pedagogique/Ndèye_Fatou_NIANG.png"
import ndeye_yande from "../assets/images/home/staff/pedagogique/Ndeye_Yandé_DIOP.jpg"
import samba_ndiaye from "../assets/images/home/staff/pedagogique/Pr_Samba_NDIAYE.jpg"
import sekou_dieme from "../assets/images/home/staff/pedagogique/Séckou_DIEME.png"

//********************************** ADMINISTRATIF ******************************************** */
import fatou_diagne from "../assets/images/home/staff/administratif/fatou_diagne.jpg"
import balle from "../assets/images/home/staff/administratif/balle.jpeg"
import nguenar_diouf from "../assets/images/home/staff/administratif/nguenar_diouf.jpg"
import marianne_toure from "../assets/images/home/staff/administratif/marianne_toure.jpg"
import jonas_ndecky from "../assets/images/home/staff/administratif/jonas_ndecky.jpg"
import madieng_top from "../assets/images/home/staff/administratif/madieng_top.jpg"

//********************************** TECHNIQUE ************************************************* */
import ousmane_diagne from "../assets/images/home/staff/technique/ousmane_diagne.jpg"
import amadou_ly from "../assets/images/home/staff/technique/amadou_ly.jpeg"

import lamine_fane from "../assets/images/new_eao/Lamine.jpg"
import el_hadj from "../assets/images/new_eao/El_hadj.jpg"
import anna from "../assets/images/new_eao/anna_diouf.jpg"
import bouna_ndao from "../assets/images/new_eao/Bouna.jpg"
import fondateur from "../assets/images/new_eao/fondateur.jpg"


export const staff = [
    {
        name:"Dr Cheikh Omar DIALLO",
        staff_statut:"Pédagogique",
        image:fondateur,
        title:"Fondateur-Président",
        profession:"Juriste, Docteur en Science Politique, Expert en Communication et Leadership, Dr Diallo anime les Ateliers fondamentaux en Art Oratoire, Leadership et Charisme. Il capitalise plus d'une vingtaine d'années d'expérience. Ses ouvrages sur le Leadership et la communication font autorité."

    },
    {
        name:"Pr Samba NDIAYE",
        staff_statut:"Pédagogique",
        image:samba_ndiaye,
        title:"Professeur de Lettres classiques et modernes",
        profession:"Expert - Consultant en Communication. Spécialiste en Diction, Ponctuation et Articulation - Maîtrise de la voix - Charisme oratoire - Prise de Parole en Public - Techniques rédactionnelles."

    },
    {
        name:"Coach Pierre AVOUDJIGBE",
        staff_statut:"Pédagogique",
        image:pierre_avoudjigbe,
        title:"Directeur Pédagogique",
        profession:"Spécialiste en Art Oratoire et Développement Personnel, Coach Pierre capitalise une vingtaine d'années d'expérience dans la Prise de Parole, la Stratégie d'éloquence, le Leadership et le Team bulding"

    },
    {
        name:"M. Charles FAYE",
        staff_statut:"Pédagogique",
        image:charles_faye,
        title:"Journaliste-Chroniqueur",
        profession:"Il est journaliste à Rfm et à Tfm. M. Faye capitalise 25 ans d'expérience dans les médias. Maître-étalon de l'image et du charisme télévisuel, il est le spécialiste-maison télé et radio"

    },
    {
        name:"M. Mamadou CISS",
        staff_statut:"Pédagogique",
        image:mamadou_ciss,
        title:"Doctorant en Finances Publiques",
        profession:"Mamadou Ciss est doctorant en Droit. Certifié en Leadership et Art Oratoire, il est le spécialiste des Techniques de Présentation de Projets et de l'Art du Pitch. Il est également Formateur en Développement des compétences"

    },
    {
        name:"Coach Ndèye Fatou NIANG",
        staff_statut:"Pédagogique",
        image:ndeye_fatou,
        title:"Journaliste-Communicante",
        profession:"Ateliers Prise de Parole en Public et Leadership. Spécialiste en communication, développement personnel et leadership"
    },
    {
        name:"Coach Matar FAYE",
        staff_statut:"Pédagogique",
        image:matar_faye,
        title:"Expert en Gestion de projets",
        profession:"Directeur du département EAO Masterclass - Etudiants. Ateliers Techniques de Présentation de Projets. Diplômé en Master Gestion des Projets, il a accompagné une vingtaine d'entreprises, PMI-PME, à développer leurs chiffres d'affaires, lancer de nouveaux projets"
    },
    {
        name:"Coach Diamant ESSOU",
        staff_statut:"Pédagogique",
        image:diamant_essou,
        title:"Doctorant en Droit",
        profession:"Atelier Prise de Parole en Public et Techniques Vocales et Oratoires. Coach en Art Oratoire, techniques de présentation de projet et animateur des ateliers d'écriture et d'oralité, il capitalise six années d'expériences."

    },
    {
        name:"Coach Ndeye Yandé DIOP",
        staff_statut:"Pédagogique",
        image:ndeye_yande,
        title:"Formatrice",
        profession:"Ateliers Prise de Parole en Public et Leadership. Ingénieure en Finance, elle s'est spécialisée en communication, développement personnel et leadership. Certifiée coach mentor formatrice dans plusieurs institutions du Sénégal, elle capitalise une dizaine d'années d'expérience."

    },
    {
        name:"Coach Séckou DIEME",
        staff_statut:"Pédagogique",
        image:sekou_dieme,
        title:"Journaliste-Rédacteur en Chef à la 7TV",
        profession:"Homme de médias, présentateur vedette des JT et JP, rédacteur en chef, il est fort d'une vingtaine années d'expérience dans le journalisme, le média-training, la communication institutionnelle et des organisations."

    },
    {
        name:"Coach Ndéné FAYE",
        staff_statut:"Pédagogique",
        image:ndeye_faye,
        title:"Formateur",
        profession:"Spécialiste en Management des Talents et Développement des Compétences en Equipes. Expert en Vente Complexe et Négociation Stratégique, il capitalise plus de trente ans d'expériences en entreprise, notamment dans de grands groupes anglo-saxons."

    },
    {
        name:"M. Mouhamadou Moustapha LO",
        staff_statut:"Pédagogique",
        image:mouhamadou,
        title:"Juriste et Expert Formateur",
        profession:"Juriste et formateur, doté d'une forte expérience en management des connaissances et en gestion des ressources humaines. Focalisation sur la formation professionnelle, le développement de compétences, le recrutement, le suivi-évaluation et la gestion des talents et des carrières."

    },
    {
        name:"Madieng TOP",
        staff_statut:"Administratif",
        image:madieng_top,
        title:"Directeur Général",
        profession:""

    },
    {
        name:"Ballé Bathily",
        staff_statut:"Administratif",
        image:balle,
        title:"Assistante d'accueil et Orientation.",
        profession:""

    },
    {
        name:"M. Jonas NDECKY",
        staff_statut:"Administratif",
        image:jonas_ndecky,
        title:"Directeur Général Adjoint(DGA) en Charge des Finances et Moyens Généraux (DFAM)",
        profession:""

    },
    {
        name:"Mme Nguenar DIOUF",
        staff_statut:"Administratif",
        image:nguenar_diouf,
        title:"Directrice des Etudes et Formations",
        profession:""

    },
    {
        name:"Mme Marianne Toure SARR",
        staff_statut:"Administratif",
        image:marianne_toure,
        title:"Responsable Chargée des Entreprises",
        profession:""

    },
    {
        name:"M. Lamine FANE",
        staff_statut:"Technique",
        image:lamine_fane,
        title:"Coordinateur de la Cellule Audiovisuelle",
        profession:""

    },
    {
        name:"M. Ousmane DIAGNE",
        staff_statut:"Technique",
        image:ousmane_diagne,
        title:"Technicien audiovisuel & infographiste",
        profession:""

    },
    {
        name:"Bouna NDAO",
        staff_statut:"Technique",
        image:bouna_ndao,
        title:"Community Manager",
        profession:""

    },
    {
        name:"El Hadji Mbaye NDIAYE",
        staff_statut:"Technique",
        image:el_hadj,
        title:"Technicien Audiovisuel Infographe",
        profession:""

    },
    
]