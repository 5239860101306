import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import {useTypewriter,Cursor} from "react-simple-typewriter"
import Button_atelier from "../Buttons/Button_Atelier/Button_Atelier";

const ShuffleHero = () => {
  const [formations] = useTypewriter({
    words: ['Leadership','Art de l\'Improvisation','Communication de Crise'],
    loop:{},
    typeSpeed: 200,
    delaySpeed: 40
  })
  return (
    <section className="w-full px-8 py-12 grid grid-cols-1 md:grid-cols-2 items-center gap-8 max-w-6xl mx-auto">
      <div>
        <span className="block mb-4 text-xs md:text-sm text-orange-500 font-medium">
          Meilleur à chaque fois
        </span>
        <h3 className="text-4xl md:text-6xl font-semibold ">
          NOS ATELIERS:
        </h3>
        <h4 className="mt-3">
            <span className="text-orange-600 text-5xl md:max-xl:text-4xl">{formations}</span>
              
        </h4>
        <p className="text-base md:text-lg text-slate-700 my-4 md:my-6">
          
        </p>
        <br /><br />
        <Button_atelier text={""}/>
      </div>
      <ShuffleGrid />
    </section>
  );
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const squareData = [
  {
    id: 1,
    src: "https://media.licdn.com/dms/image/D4E05AQHtC6bIBjuT1A/videocover-high/0/1695667678785?e=2147483647&v=beta&t=8PZupNwWiONkvQih3uzQQ3RgSX6JyqsIdBqWP7XSyP4",
  },
  {
    id: 2,
    src: "https://www.financialafrik.com/wp-content/uploads/2017/07/10536300-17310631.jpg",
  },
  {
    id: 3,
    src: "https://media.licdn.com/dms/image/D4D22AQFpBzDzmiYWHg/feedshare-shrink_800/0/1695219198180?e=1698883200&v=beta&t=xlv8l2WXhGQV26UfteBzQtzk-WkPrczOxNyZ2RAMGa4",
  },
  {
    id: 4,
    src: "https://www.seneplus.com/sites/default/files/raw_photos/dr-cheikh-diallo.jpg",
  },
  {
    id: 5,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjKY0JX1UKfFOdgOBuFbwHroVkYl-6AWrTOxmHDwxcNRtSZg1jBgNfYbUfe88HadFeQus&usqp=CAU",
  },
  {
    id: 6,
    src: "https://media.licdn.com/dms/image/C4D03AQGxRlOkupMpFQ/profile-displayphoto-shrink_200_200/0/1554292422519?e=1702512000&v=beta&t=qGSDhb1Hf2u_7fZ08amLDh1_ezLWMoVepO6XtixTeVM",
  },
  {
    id: 7,
    src: "https://i0.wp.com/eao-afrique.com/wp-content/uploads/2023/03/WhatsApp-Image-2023-03-22-a-13.52.53.jpg?fit=800%2C531&ssl=1",
  },
  {
    id: 8,
    src: "https://eao-afrique.com/wp-content/uploads/2021/05/img-20210327-wa00401826090837375304655.jpg",
  },
  {
    id: 9,
    src: "https://i0.wp.com/gnatepe.com/wp-content/uploads/2022/06/ARO-INTERNATIONAL-Lecole-par-excellence-de-lArt-oratoire-annonce-ses-plus-belles-couleurs-au-Togo.jpg?fit=1200%2C675&ssl=1",
  },
  {
    id: 10,
    src: "https://www.jeuneafrique.com/medias/2017/08/18/aaeaaqaaaaaaaaqyaaaajgq1m2rhoty0lwnkmzktngzins1hy2nllwy3mtrjnty4ytuzmw-1-592x296.jpg",
  },
  {
    id: 11,
    src: "https://www.barakagate.com/IMG/arton27.jpg",
  },
  {
    id: 12,
    src: "https://eao-afrique.com/wp-content/uploads/2021/06/img-20210605-wa00174218697719823588197.jpg",
  },
  {
    id: 13,
    src: "https://media.licdn.com/dms/image/D5622AQFPPQ1ZucwPcg/feedshare-shrink_800/0/1695170516994?e=1698883200&v=beta&t=RccOdOEumObysM7dc_VWDxPDPmO8MYgKhPnThtc7VRo",
  },
  {
    id: 14,
    src: "https://media.licdn.com/dms/image/D4E22AQGKDJm5ErIWIA/feedshare-shrink_800/0/1695300291687?e=1698883200&v=beta&t=CDOTo2518ovbhEzBi4hUhhi66Ke4vbuXoVGmhJko0kA",
  },
  {
    id: 15,
    src: "https://i0.wp.com/eao-afrique.com/wp-content/uploads/2022/04/DSC08155-scaled.jpg?fit=800%2C450&ssl=1",
  },
  {
    id: 16,
    src: "https://media.licdn.com/dms/image/C4E22AQHwL7ok2B5zMg/feedshare-shrink_2048_1536/0/1619090705338?e=2147483647&v=beta&t=IsKxQtUMahrw5REW8dCHFsAtaHlCv_TfmrtvHrn7ep0",
  },
];

const generateSquares = () => {
  return shuffle(squareData).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: "spring" }}
      className="w-full h-full"
      style={{
        backgroundImage: `url(${sq.src})`,
        backgroundSize: "cover",
      }}
    ></motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());

  useEffect(() => {
    shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="grid grid-cols-4 grid-rows-4 h-[450px] gap-1">
      {squares.map((sq) => sq)}
    </div>
  );
};

export default ShuffleHero;