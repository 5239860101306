import React from 'react'
import ReactPlayer from 'react-player'
import './video_Atelier.css'

const VideoAtelier = ({external_video,description}) => {
  return (
        <div class="card" style={{width: "100%",height: "98%"}}>
            <ReactPlayer url={external_video} 
                controls
                playing
                loop
                muted
                
                width="100%"
                height="100%"
                className="player-video"
            />
        </div>
  )
}

export default VideoAtelier

