//import formation_entreprise from '../assets/images/ecole/formation_entreprise.webp'


import w_8 from "../assets/images/divers/w8.jpg"
import w_18 from "../assets/images/divers/w18.jpg"

import formation_entreprise from "../assets/images/new_eao/entreprise.jpg"
import masterclass from "../assets/images/new_eao/masterclass.jpg"
import coaching_individuel from "../assets/images/new_eao/coaching_individuel.jpg"
import parcours_managerial from "../assets/images/new_eao/parcours_managerial.webp"

import { faChildReaching,faBuilding,faBrain,faComments } from '@fortawesome/free-solid-svg-icons'


export const formations=[
    {
        image:formation_entreprise,
        title:"Formation des Entreprises",
        subtitle:"Formation des Entreprises",
        icon:faBuilding
    },
    {
        image:w_8,
        title:"Entrepreunariat Jeunes",
        subtitle:"Entrepreunariat Jeunes",
        icon:faChildReaching
    },
    {
        image:masterclass,
        title:"MasterClass Etudiants",
        subtitle:"MasterClass Etudiants",
        icon:faBrain
    },
    {
        image:w_18,
        title:"Orateurs Juniors",
        subtitle:"Orateurs Juniors",
        icon:faComments
    },
    {
        image:coaching_individuel,
        title:"Coaching Individuel",
        subtitle:"Coaching Individuel",
        icon:faComments
    },
    {
        image:parcours_managerial,
        title:"Parcours Managérial",
        subtitle:"Parcours Managérial",
        icon:faComments
    },

]