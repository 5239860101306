import React from 'react'
import "./card_dep.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const CardDep = ({image,title,subtile,icon}) => {
  return (
    <div className='card_dep'>
        
        <div className='card_img_bg'>
          <img src={image}  id='a'/>
        </div>
        
        
        <div className='card_dep_bottom ' id='b'>
            <div className='card_dep_bottom_head'>
              <div className='card_dep_body'>
                  <hr className='trait' />
                  <h1 className='title'>{title}</h1>
              </div>
              <div className='card_dep_bottom_head_icon'>
              <FontAwesomeIcon icon={icon} style={{width:"25px",height:"25px",color:"#FFA500"}}/>
              </div>
              
            </div>
            
            <h1 className='card_dep_bottom_subtitle'>{subtile}</h1>
        </div>
       
    </div>
  )
}

export default CardDep