import React from 'react'
import "./card_atelier.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Reveal from '../../../components/Reveal/Reveal'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

import ptit_diallo from "../../../assets/images/home/formateurs/little/dr_diallo_blur.png"

const Card_Atelier = ({title,image,icon,zone}) => {
  return (
    
    <div className='card_atelier_header' style={{width:"300px",height:"40vh"}}>
      
      <div style={{position:"absolute",inset:"0",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
      {/*<FontAwesomeIcon icon={icon} style={{fontSize:"40px",color:"white",textShadow:"2px 2px #fff",zIndex:"52"}} />*/}
        <h1 style={{color:"white",fontSize:"25px",fontWeight:"400",fontFamily:"Impact",textShadow:"2px 2px #000",zIndex:"52",textAlign:"center"}}>{title}</h1>
      </div>
      
        <LazyLoadImage 
          effect='blur'
          src={image}
         
          
          style={{objectFit:"cover",width:"300px",height:"40vh"}}
          placeholderSrc={ptit_diallo}
        />
        
    
    <div className='' style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%",backgroundColor:"rgba(0,0,0,0.6",zIndex:"51"}}></div>
      
        
        
    </div>
   
  )
}

export default Card_Atelier