import React from 'react';

import "./footer.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF,faLinkedin, faXTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons';
import eao_logo from "../../assets/images/eao_logo.png"

function Footer() {

 const toAnotherPage = (link)=>{
  window.location.href = link;
 }

  return (
    <footer class="footer-section">
        <div class="container">
            <div class="footer-cta pt-5 pb-5">
                <div class="wrap_bloc_footer_parent_head">
                    <div class="wrap_bloc_footer_head">
                        <div class="single-cta click-moi" onClick={()=>toAnotherPage("https://www.google.com/maps/place/School+Of+Art+Oratoire+Et+De+Leadership/@14.724097,-17.4743888,17z/data=!3m1!4b1!4m6!3m5!1s0xec10dfd3c699b6b:0x6da6504cda941eac!8m2!3d14.7240918!4d-17.4718139!16s%2Fg%2F11h4b967_2?entry=ttu")}>
                            <i class="fas fa-map-marker-alt"></i>
                            <div class="cta-text">
                                <h4>Localisation</h4>
                                <span>Voie de degagement N, Dakar 10555, Sénégal</span>
                            </div>
                        </div>
                    </div>
                    <div class="wrap_bloc_footer_head">
                        <div class="single-cta">
                            <i class="fas fa-phone"></i>
                            <div class="cta-text">
                                <h4>Contactez-nous au:</h4>
                                <span>+221 33 860 67 32</span>
                            </div>
                        </div>
                    </div>
                    <div class="wrap_bloc_footer_head">
                        <div class="single-cta">
                            <i class="far fa-envelope-open"></i>
                            <div class="cta-text">
                                <h4>Mail</h4>
                                <span>contact@eao-afrique.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-content pt-5 pb-5">
                <div class="wrap_bloc_footer_parent_head">
                    <div class="wrap_bloc_footer_head_">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <a href="index.html"><img src={eao_logo} class="img-fluid" alt="logo" /></a>
                            </div>
                            <div class="footer-text">
                                <p>NOUS ALLONS VOUS RÉVÉLER À VOUS-MÊMES</p>
                            </div>
                            <div class="footer-social-icon">
                                <span>Follow us</span>
                                <div className='footer-social-icon_horizontal'>
                                  <div className='footer-social-wrap'style={{backgroundColor:"#0866FF"}}
                                      onClick={()=>toAnotherPage("https://www.facebook.com/EAO.officiel")}>
                                  <FontAwesomeIcon icon={faFacebookF} style={{color:"white"}}/>
                                  </div>
                                  <div className='footer-social-wrap'onClick={()=>toAnotherPage("https://twitter.com/EAO_officiel")} style={{backgroundColor:"black"}}>
                                  <FontAwesomeIcon icon={faXTwitter} style={{color:"white"}}/>
                                  </div>
                                  <div className='footer-social-wrap'style={{backgroundColor:"red"}}
                                      onClick={()=>toAnotherPage("https://www.youtube.com/@ecoledartoratoirededakar8600")}>
                                  <FontAwesomeIcon icon={faYoutube} style={{color:"white"}}/>
                                  </div>
                                  <div className='footer-social-wrap'style={{backgroundColor:"#0A66C2"}}
                                      onClick={()=>toAnotherPage("https://www.linkedin.com/company/ecole-africaine-d-art-oratoire-de-dakar/")}>
                                  <FontAwesomeIcon icon={faLinkedin} style={{color:"white"}}/>
                                  </div>
                                 
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="wrap_bloc_footer_head_">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Pages du Site</h3>
                            </div>
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">Ecole</a></li>
                                <li><a href="#">Ateliers</a></li>
                                <li><a href="#">Divers</a></li>
                                <li><a href="#">Contact</a></li>
                                <li><a href="#">A propos</a></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="copyright-area">
                 
              <div class="copyright-text">
                  <p>Copyright &copy; 2023, Tous les droits réservés <span>EAO</span></p>
              </div>
                   
        </div>
    </footer>
  );
}

export default Footer;

