import livre_ouvert from '../assets/images/divers/livre_ouvert.jpg'
import livre_ouvert_blanc from '../assets/images/divers/libre_ouvert_blanc.jpg'

import hand_world from '../assets/images/divers/bibliothèque.jpg'

import parole_gouverne from '../assets/images/divers/Parole_gouverne.jpg'
import peur_public from '../assets/images/divers/peur_de_parler.jpg'
import leadership from '../assets/images/divers/leadership.jpg'

import humour_de_a_z from '../assets/images/divers/Livre.jpg'


export const livres_as_bg=[
    {
        id:1,
        image:livre_ouvert,
        title:'Livres',

    },
    {
        id:2,
        image:livre_ouvert_blanc,
        title:'Livres',

    },
    {
        id:3,
        image:hand_world,
        title:'Livres',
    },
    

]

export const livres_dr_cheick=[
    {
        id:1,
        image:parole_gouverne,
        title:"La Parole Gouverne le Monde"
    },
    {
        id:2,
        image:leadership,
        title:"Un leader dit allons-y"
    },
    {
        id:3,
        image:peur_public,
        title:"La Peur de Parler en public"
    },
    
    {
        id:4,
        image:humour_de_a_z,
        title:"Humour de A à Z"
    }
]