import React,{useEffect,useRef} from 'react'
import {motion, useInView,useAnimation} from "framer-motion"
import { Opacity } from '@material-ui/icons'



const Reveal_Atelier = ({children}) => {
    const ref = useRef(null)
    const isInView = useInView(ref, {once:true})

    const mainControls = useAnimation()
    const slideControls = useAnimation()

    useEffect(() =>{
        if(isInView){
            mainControls.start("visible")
            slideControls.start("visible")
        }

    },[isInView])

  return (
    <div  ref={ref} style={{postion:"relative",width:"300px",overflow:"hidden"}}>
        <motion.div
            variants={{
                hidden:{opacity:0, y:75},
                visible:{opacity:1, y:0},

            }}
            initial='hidden'
            animate={mainControls}
            transition={{duration:0.5, delay:0.25}}
        >
            {children}
        
        </motion.div>
       
    </div>
  )
}

export default Reveal_Atelier