import React, { useState } from 'react'
import "./contact_us.css"
import { motion } from 'framer-motion'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

import contact from "../../assets/images/contact/contact.jpg"
import contact_blur from "../../assets/images/contact/contact_blur.png"
import Reveal from '../../components/Reveal/Reveal';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/test/Footer';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RingLoader } from 'react-spinners'
import { SENDMESSAGE } from '../../routes/routes';

import axios from "axios"
import { FlyoutNav } from '../../components/New/HomeHeader';


const Contact_US = () => {

  const toAnotherPage = (link)=>{
    window.location.href = link;
   }

   const [name,setName] = useState('')
   const [email,setEmail] = useState('')
   const [message,setMessage] = useState('')

   const [loading,setLoading] = useState(false)

   const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

      const data = {
        nom:name,
        email: email,
        msg: message
      }

      axios.post(`${SENDMESSAGE}api/prospect/sendMessage`,data)
      .then(()=>{
        notify()
        setEmail('')
        setMessage('')
        setName('')
        setLoading(false)
      }).catch((error) => {
        console.log('Error sending email',error);
        setLoading(false)
       });
      
   }

   
   const notify = () => toast.success('Votre message a été envoyé avec succès', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

  return (
    <motion.div
      initial={{ opacity : 0 }}
      animate={{ opacity : 1 }}
      exit={{ opacity : 0 }}
    >
      <FlyoutNav />
     
    <motion.div 
      className="contact_carousel"
      
      initial={{ opacity : 0 }}
      animate={{ opacity : 1 }}
      exit={{ opacity : 0 }}
    >
      
  
      <div className='header_contact_carousel' >
        <div className="header_contact_wrap_image">
          <LazyLoadImage 
            effect="blur"
            src={contact}
            alt="Image Alt"
            key="1"
            className="header_contact_image" 
            height={"100%"}
            width={"100%"}
            placeholderSrc={contact_blur}
            />
        </div>
        
        <section className='contact_introduction'>
            <Reveal>
              <div class="content_intro_">
                  <h2 style={{color:"orange"}}>CONTACT</h2>
              </div>
            </Reveal>
        </section>

          <div class="custom-shape-divider-bottom-1694260240">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
            </svg>
        </div>

        </div>


        <Reveal>
        <section>
                
                <div class="container">
                <div class="row">
                    
                    <div class="contact-info">
                    <div class="contact-info-item click-moi" onClick={()=>toAnotherPage("https://www.google.com/maps/place/School+Of+Art+Oratoire+Et+De+Leadership/@14.724097,-17.4743888,17z/data=!3m1!4b1!4m6!3m5!1s0xec10dfd3c699b6b:0x6da6504cda941eac!8m2!3d14.7240918!4d-17.4718139!16s%2Fg%2F11h4b967_2?entry=ttu")}>
                        <div class="contact-info-icon">
                        <i class="fas fa-home"></i>
                        </div>
                        
                        <div class="contact-info-content">
                        <h4>Adresse</h4>
                          
                        <p>Voie de degagement N,<br/> Dakar 10555, <br/>Sénégal</p>
                        </div>
                    </div>
                    
                    <div class="contact-info-item">
                        <div class="contact-info-icon">
                        <i class="fas fa-phone"></i>
                        </div>
                        
                        <div class="contact-info-content">
                        <h4>Téléphone</h4>
                        <p>+221 33 860 67 32</p>
                        <p>+221 78 120 11 38</p>
                        <p>+221 78 231 69 29 </p>
                        </div>
                    </div>
                    
                    <div class="contact-info-item">
                        <div class="contact-info-icon">
                        <i class="fas fa-envelope"></i>
                        </div>
                        
                        <div class="contact-info-content">
                        <h4>Email</h4>
                        <p>contact@eao-afrique.com</p>
                        </div>
                    </div>
                    </div>
                    
                    <div className="contact-form">
                    <form action="" id="contact-form" onSubmit={handleSubmit}>
                        <h2>Envoyez un Message</h2>
                        <div className="input-box">
                          <input type="text" 
                            required="true" 
                            value={name}
                            className="entree_visiteur"
                            placeholder='Entrez votre nom'
                            onChange={(e) => setName(e.target.value)}/>
                            
                          <span></span>
                        </div>
                        
                        <div className="input-box">
                          <input type="email" 
                            required="true" 
                            value={email}
                            className="entree_visiteur"
                            placeholder='Entrez votre email'
                            onChange={(e) => setEmail(e.target.value)}
                            />
                            
                          <span></span>
                        </div>
                        
                        <div className="input-box">
                          <textarea 
                            required="true"
                            value={message}
                            placeholder='Redigez votre message'
                            className="entree_visiteur"
                            onChange={(e) => setMessage(e.target.value)}>
                          </textarea>
                          <span></span>
                        </div>
                        
                      
                          <button type="submit" name="" className='bouton_envoi' disabled={loading}>
                              Envoyez
                          </button>
                          {
                            loading ? 
                            <div className='loading'>
                            <RingLoader
                                height={40}
                                width={40}
                                color="orange"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#000814"
                                strokeWidth={4}
                                strokeWidthSecondary={2}

                                />
                            </div>:""
                          }
                        
                    </form>
                    </div>
                    
                </div>
                </div>
                
            </section>

            
        </Reveal>
           
        
        
    </motion.div>
    <ToastContainer />
    <Footer />
    
    </motion.div>
  )
}

export default Contact_US