import React, { useEffect, useRef, useState } from 'react'

const useTypingEffect = (textToType,interKeyStrokeDurationInMs) => {
  
    const [currentPosition,setCurrenPosition] = useState(0)
    const currentPositionRef = useRef(0)

    useEffect(()=>{
        const intervalId = setInterval(() => {
            setCurrenPosition((value) => value +1)
            currentPositionRef.current += 1
            if(currentPositionRef > textToType.length){
                clearInterval(intervalId)
            }
        }, interKeyStrokeDurationInMs)

        return () => {
            clearInterval(intervalId)
            currentPositionRef.current = 0
            setCurrenPosition(0)
        }
    }, [interKeyStrokeDurationInMs,textToType])

    return textToType.substring(0, currentPosition)
}

export default useTypingEffect