import React from 'react'
import "./team.css"

const TeamMembers = ({image, name, fonction, description}) => {
  return (
    <div class="responsive-container_team-block container_team">
        
        <div class="w-full flex justify-center items-center">
            <div class="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12 card-container_team w-full">
                <div class="card_team w-full">
                    <div class="team-image-wrapper">
                        <img class="team-member-image" src={image} />
                    </div>
                    <p class="text-blk name text-2xl">
                        {name}
                    </p>
                    <p class="text-blk position">
                        {fonction}
                    </p>
                    <p class="text-blk feature-text text-sm">
                        {description}
                    </p>
                    <div class="social-icons">
                    <a href="https://www.twitter.com" target="_blank">
                        <img class="twitter-icon" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/Icon.svg" />
                    </a>
                    <a href="https://www.facebook.com" target="_blank">
                        <img class="facebook-icon" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/Icon-1.svg" />
                    </a>
                    </div>
                </div>
            </div>  
        </div> 
    </div>
  )
}

export default TeamMembers