import { AnimatePresence, motion } from "framer-motion";
import { FiPlus } from "react-icons/fi";
import React, { useState } from "react";

export const VosLimits = () => {

  return (
    <section className="overflow-hidden bg-slate-900 px-4 py-12 text-slate-50 w-full min-h-[90vh] motivation">
      <Heading />
    </section>
  );
};

const Heading = () => {
  return (
    <>
      <div className="relative z-10 flex flex-col items-center justify-center">
        <span className="mb-8 bg-gradient-to-r from-violet-500 to-indigo-500 bg-clip-text font-medium text-transparent">
          Docteur Cheikh DIALLO
        </span>
        <span className="mb-8 text-5xl font-bold text-center leading-loose">LA PAROLE GOUVERNE LE MONDE</span>
      </div>

      <span className="absolute -top-[350px] left-[50%] z-0 h-[500px] w-[600px] -translate-x-[50%] rounded-full bg-gradient-to-r from-violet-600/20 to-indigo-600/20 blur-3xl" />
    </>
  );
};
