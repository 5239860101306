import React from 'react'
import ReactPlayer from 'react-player'
import "./video.css"

import video from "./verbale.mp4"

const VideoT = ({link}) => {
    
  return (
    <div className='video_t' style={{width:"150vh",height:"70vh"}}>
       <ReactPlayer url={link} 
                playing
                muted
                controls
                width="100%"
                height="100%"
                
                className="player-video_temoin"
            />
    </div>
  )
}

export default VideoT