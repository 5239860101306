import React from 'react'
import "./ecole.css"

import ecole_video from "../../assets/videos/ecole.mp4"
import CardDep from './components/CardDep'

import { formations } from '../../constants/ecole'

import micro from '../../assets/images/micro.jpg'

import {motion} from "framer-motion"
import Reveal from '../../components/Reveal/Reveal'

import useTypingEffect from "../../hook/typing-effect"
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/test/Footer'


import lettre_du_directeur from "../../assets/pdf/director_letter.pdf"

import { useNavigate } from 'react-router-dom'
import { VIEW_PDF } from '../../routes/routes'
import { FlyoutNav } from '../../components/New/HomeHeader'

const Ecole = () => {

    const text = useTypingEffect("Mot du Directeur",300)
    const ecole = useTypingEffect("ECOLE",300)

    const navigate = useNavigate()
    

  return (
    <motion.div
      initial={{ opacity : 0 }}
      animate={{ opacity : 1 }}
      exit={{ opacity : 0 }}
    >
        <FlyoutNav />
        
        <motion.div 
        className='ecole ripple-background'
        initial={{ opacity : 0 }}
        animate={{ opacity : 1 }}
        exit={{ opacity : 0 }}
    >
        <div className='circle xxlarge shade1'>
        </div>
        
      
        <div className='ecole_head'>
            <video src={ecole_video} width="100%" autoPlay loop/>
        </div>

        <Reveal>
        <div className='ecole_intro_bloc' style={{marginTop:"10vh"}}>
            <div className="ecole_intro_description">
                <Reveal>

                <h1 className='ecole_intro_description_h1'>NOTRE {ecole}</h1>
                <p className='mb-4 ecole_intro_description_p'>Première du genre en Afrique, notre école contribue considérablement au renforcement des capacités oratoires des 
                    cadres de la haute administration, des PME et PMI, des Organismes internationaux et des ONGs. Notre cœur de métier 
                    va de la prise de parole en public au conseil en communication pour les entreprises, en passant par
                    la maîtrise des techniques vocales et oratoires, le media-training, ou le coaching en leadership et développement 
                    personnel.
                </p>


                <button class="body_home_button-86" role="button" onClick={()=> navigate(VIEW_PDF,{state:{link: lettre_du_directeur}})}>{text}</button>


                </Reveal>
                
          
            </div>
            
            <div class="fancy-border-radius">
                <img className='' src={micro} />
            </div>       
            
        </div>

        </Reveal>
        


        <div className='ecole_departement'>
            <h1>FORMATIONS</h1>
            <hr />
            <div className='ecole_body_departement'>
                
                <div className='departements'>
                    {formations.map((item,index)=>{
                        return (
                            <CardDep image={item.image} title={item.title} subtile={item.subtitle} icon={item.icon}/>
                        )
                    })}
                    
                </div>
            </div>

            
        </div>

        
       </motion.div>

       <Footer />
    </motion.div>
  )
}

export default Ecole