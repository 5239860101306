

import uemoa from "../assets/images/home/Logo_UEMOA.png"
import orabank from "../assets/images/home/orabank-logo.jpg"
import port_autonome from "../assets/images/home/port autonome.png"
import b_islamique from "../assets/images/home/islamique.png"
import sedima from "../assets/images/home/sedima.png"
import senelec from "../assets/images/home/senelec.png"
import sonatel from "../assets/images/home/sonatel.JPG"
import onas from "../assets/images/home/onas.png"
import dangote from "../assets/images/home/dangote_cement.webp"
import cosec from "../assets/images/home/cosec.png"
import cbao from "../assets/images/home/cbao.png"
import canal from "../assets/images/home/Canal-Logo.jpg"


import coach_pierre from '../assets/images/home/coach_pierre.jpg'
import dr_smile_hand from '../assets/images/home/dr_smile_hand.jpg'
import conference from '../assets/images/home/conference.webp'
import dr_cheickh_conseil from '../assets/images/home/dr_cheick_conseil.png'



export const images={
    coach_pierre:{
        title:'coach pierre',
        image:coach_pierre
    },
   
    dr_cheick_smile:{
        title:'dr Cheickh DIALLO',
        image:dr_smile_hand
    },
    conference:{
        title:'conférence',
        image:conference
    },
    dr_cheickh_conseil:{
        title:'conseil',
        image:dr_cheickh_conseil
    },
}

export const data_home=[
    {
    "title":"A propos",
    "content":"L\’École d’Art Oratoire et de Leadership (EAO) est une institution spécialisée dans la prise de parole en public et le leadership. "+ 
              "Nos formations sont destinées aux leaders, managers et décideurs du Sénégal et du continent africain. "+ 
              "L\’EAO a déjà formé plus de 7000 orateurs de la haute administration : ministres, ambassadeurs, cadres des Impôts et Domaines, du Trésor, "+ 
              "de la Douane, mais aussi du secteur privé : patronat, banques, téléphonie, finance, assurance, etc."
    },
  ]

export const partenaires = [
    {
      source:uemoa,
      title:"uemoa"
    },
    {
      source:orabank,
      title:"orabank"
    },
    {
      source:b_islamique,
      title:"banque islamique"
    },
    {
      source:port_autonome,
      title:"port autonome"
    },
    {
      source:sedima,
      title:"sedima"
    },
    {
      source:dangote,
      title:"dangote cement"
    },
    {
      source:senelec,
      title:"senelec"
    },
    {
      source:onas,
      title:"onas"
    },
    {
      source:sonatel,
      title:"sonabel"
    },
    {
      source:cosec,
      title:"cosec"
    },
    {
      source:cbao,
      title:"cbao"
    },
    {
      source:canal,
      title:"canal"
    },
    {
      source:uemoa,
      title:"uemoa"
    },
    {
      source:orabank,
      title:"orabank"
    },
    {
      source:b_islamique,
      title:"banque islamique"
    },
    {
      source:port_autonome,
      title:"port autonome"
    },
    {
      source:sedima,
      title:"sedima"
    },
    {
      source:dangote,
      title:"dangote cement"
    },
    {
      source:senelec,
      title:"senelec"
    },
    {
      source:onas,
      title:"onas"
    },
    {
      source:sonatel,
      title:"sonabel"
    },
    {
      source:cosec,
      title:"cosec"
    },
    {
      source:cbao,
      title:"cbao"
    },
    {
      source:canal,
      title:"canal"
    },
    
  ]