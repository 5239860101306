import React from 'react'
import "./view_pdf.css"
import { useLocation } from 'react-router-dom'
import Footer from '../../components/test/Footer'
import { motion } from 'framer-motion'
import test from "../../assets/pdf/EAO-Plaquette-2023.pdf"

const ViewPdf = () => {
    const link_pdf = useLocation().state.link
    //const link_pdf = test
  return (
    <motion.div
      initial={{ opacity : 0 }}
      animate={{ opacity : 1 }}
      exit={{ opacity : 0 }}
    >
        
    <div className=''>
        
        <div className='view_pdf w-full h-full'>
        
        <iframe src={link_pdf} className='myPDF' frameborder="0" />
    </div>
    </div>
    <Footer />
    </motion.div>
    
  )
}

export default ViewPdf