import { FiMonitor, FiSave, FiSearch } from "react-icons/fi";
import { MdOutlineCastForEducation } from "react-icons/md";
import { IoNewspaper } from "react-icons/io5";
import { LuComputer } from "react-icons/lu";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import TeamMembers from "../../pages/About/components/TeamMembers";
import { staff } from "../../constants/staff";

const Personal = () => {
  const [selected, setSelected] = useState(0);

  return (
    <section className="p-4">
      <div className="mx-auto w-full ">
        <Tabs selected={selected} setSelected={setSelected} />

        <AnimatePresence mode="wait">
          {FEATURES.map((tab, index) => {
            return selected === index ? (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                key={index}
              >
                <tab.Feature />
              </motion.div>
            ) : undefined;
          })}
        </AnimatePresence>
      </div>
    </section>
  );
};

const Tabs = ({ selected, setSelected }) => {
  return (
    <div className="flex overflow-x-scroll ">
      {FEATURES.map((tab, index) => {
        return (
          <Tab
            key={index}
            setSelected={setSelected}
            selected={selected === index}
            Icon={tab.Icon}
            title={tab.title}
            tabNum={index}
          />
        );
      })}
    </div>
  );
};

const Tab = ({ selected, Icon, title, setSelected, tabNum }) => {
  return (
    <div className="relative w-full ">
      <button
        onClick={() => setSelected(tabNum)}
        className="relative z-0 flex w-full flex-row items-center justify-center gap-4 border-b-4 border-white-200 bg-black-100 p-6 transition-colors hover:bg-white-100 md:flex-col"
      >
        <span
          className={`rounded-lg bg-gradient-to-br from-orange-600 from-10% to-orange-400 p-3 text-2xl text-white shadow-orange-400 transition-all duration-300 ${
            selected
              ? "scale-100 opacity-100 shadow-lg"
              : "scale-90 opacity-50 shadow"
          }`}
        >
          <Icon />
        </span>
        <span
          className={`min-w-[150px] max-w-[200px] text-start text-xs text-slate-600 transition-opacity md:text-center ${
            selected ? "opacity-100" : "opacity-50"
          }`}
        >
          {title}
        </span>
      </button>
      {selected && (
        <motion.span
          layoutId="tabs-features-underline"
          className="absolute bottom-0 left-0 right-0 z-10 h-1 bg-orange-400"
        />
      )}
    </div>
  );
};

const ExampleFeature = ({ Icon,groupe }) => (
    <div>
    <div className='w-full flex flex-row flex-wrap justify-center'>
      {staff.filter((item,index)=> item.staff_statut === groupe).map((item,index)=>{
        return (
          <TeamMembers image={item.image} name={item.name} fonction={item.title} description={item.profession} key={index} />
        )
      })}
    </div>
</div>
);


export default Personal;

const FEATURES = [
  {
    title: "Staff Pédagogique",
    groupe: "Pédagogique",
    Icon: MdOutlineCastForEducation ,
    Feature: () => <ExampleFeature Icon={MdOutlineCastForEducation} groupe="Pédagogique" />,
  },
  {
    title: "Staff Administratif",
    groupe: "Administratif",
    Icon: IoNewspaper,
    Feature: () => <ExampleFeature Icon={IoNewspaper} groupe="Administratif" />,
  },
  {
    title: "Staff Technique",
    groupe: "Technique",
    Icon: LuComputer,
    Feature: () => <ExampleFeature Icon={LuComputer} groupe="Technique" />,
  },
];