import React from 'react'
import "./atelier.css"
import VideoAtelier from './components/VideoAtelier'
import Card_Atelier from './components/Card_Atelier'
import { videos_ateliers, ateliers } from '../../constants/atelier'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'

import {motion} from "framer-motion"
import Reveal from '../../components/Reveal/Reveal'
import Reveal_Atelier from './components/Reveal_Atelier/Reveal_Atelier'
import ShuffleHero from '../../components/Hero/Hero'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/test/Footer'
import { FlyoutNav } from '../../components/New/HomeHeader'


const Atelier = () => {

  return (
    <motion.div
      initial={{ opacity : 0 }}
      animate={{ opacity : 1 }}
      exit={{ opacity : 0 }}
    >
        <FlyoutNav />
        
        <motion.div 
            className='atelier'
            initial={{ opacity : 0 }}
            animate={{ opacity : 1 }}
            exit={{ opacity : 0 }}
        >
            <div className='text-black-700 atelier_head'>
                <ShuffleHero />
            </div>
            
            <Reveal>
                <div className='atelier_zone'>

                    <div className='atelier_formation' > 
                        <h1>Formations</h1>
                        <p>Notre cœur de métier va de la prise de parole en public au conseil en communication pour les entreprises, en passant par
                                la maîtrise des techniques vocales et oratoires, le media-training, ou le coaching en leadership et développement personnel.</p>
                            <hr />
                    </div>
                    <div className='atelier_head' >
                        <div className='atelier_intro'>
                            <div className='atelier_desc'>
                                <Reveal>
                                <h1 className='atelier_desc_h1'>{videos_ateliers[0].description}</h1>
                                <h5 className="atelier_desc_h5" style={{marginTop:"20px",textAlign:"justify",marginBottom:"5vh"}}>Première du genre en Afrique, notre école contribue considérablement au renforcement des capacités oratoires des cadres de la haute administration, des PME et PMI, des Organismes internationaux et des ONGs. Notre cœur de métier va de la prise de parole en public au conseil en communication pour les entreprises, en passant par</h5>
                                
                                </Reveal>
                                
                            </div>
                            <div className='video_atelier'>
                                <VideoAtelier external_video={videos_ateliers[0].video} description={videos_ateliers[0].description}/>
                            </div>
                        </div>
                        
                    </div>

                </div>
            
            </Reveal>



        <Reveal>
            <div className='flex flex-col justify-center items-center' style={{width:"100%",minHeight:"100vh", marginTop:"5vh"}}> 
                <h1>NOS ATELIERS</h1>
                <div className='nos_ateliers'>
                    <Reveal>
                    <div className='nos_ateliers_interne '>
                    
                    {ateliers.map((item,index)=>{
                        return(
                            <Reveal_Atelier>
                                <Card_Atelier icon={faCirclePlus} image={item.image} title={item.title} />
                            </Reveal_Atelier>
                        
                        )
                    })}
                    
                    </div>

                    </Reveal>
                
                </div>

            </div>
        </Reveal>

            <div style={{position:"fixed", top:"40%",left:"0",width:"5vh", height:"20vh",backgroundColor:"orange"}}>

            </div>
            <div style={{position:"absolute", top:"0",left:"0",width:"100%", height:"100px",backgroundColor:"rgba(0,0,0,0.8)"}}>

            </div>
            
        </motion.div>

        <Footer />
    </motion.div>
  )
}

export default Atelier