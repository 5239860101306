import { isVisible } from "@testing-library/user-event/dist/utils";
import React, { useState } from "react";
import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";
import "./counter.css"

function Counter_stats({ className, max_number,size,color }) {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
            <CountUp  start={viewPortEntered ? null : 0} end={max_number}>
                {({ countUpRef }) => {
                  return (
                    <>
                        
                        <ReactVisibilitySensor
                            active={!viewPortEntered}
                            onChange={(isVisible) => {
                                if (isVisible) {
                                setViewPortEntered(true);
                                }
                            }}
                            delayedCall
                            >
                            <span className="number" ref={countUpRef} style={{color:color}}/>
                        </ReactVisibilitySensor>
                    </>
                    
                  );
                }}
              </CountUp>
  );
}

export default Counter_stats;
